import './index.scss'
import SliderBox from '../../components/slider-box'

export default function About(props: { visible: boolean }) {
  return (
    <div className="about pt-50 pl-52 pr-52">
      <SliderBox active={props.visible} time={1}>
        <div>
          <p className="fs-16 lh-24">
            关于安裕普，拥有87000m现代化生产基地。主要从事高低压电器，成套设备汽车充电桩、电力变压器、变频器等产品的研发、生产和销售。安裕普集团有限公司成立于1997年，集团公司注册资金1.6亿实业的发展。
          </p>
          <p className="fs-16 lh-24 mt-20">
            安裕普新能源公司隶属于安裕普集团旗下，致力于推动新能源生产的系列智能充电桩产品，覆盖3.5KW到720KW全功率。造光、储、充一体化智能超级充电产品，为全球客户提供量身定制的智慧充电解决方案。深圳安裕普科技有限公司，是深圳安裕普控股有限公司旗下提供一站式(EPCO)围涵盖电力咨询设计(E)、电力设备供应(P)、电力工程建设(C)和智能用电服务业务(O)供用电品牌服务商，业务深圳安裕普控股有限公司旗下设有深圳安裕普科技有限公司项目运营中心)、安裕普(深圳)数字能源技术有限公司(数字能源矿发中心)、广西安裕普电力科技有限公司(电力设备生产基地)3家子公司。项目运营中心负责供用电项目、双碳(数字能源)项目的开发和运营;研发中心负责数字能源产品研发。公司总部位于深圳市福田区，设有项目运营中心和数字能源研电力设备生产基地位于南宁市高新技术开发区，在南宁十一电子信息产业园，拥有6000平方米生产厂房，负责电力成套设备、高;压电气元件和物联网智能仪表的生产，年生产能力超过12000台套。
          </p>
        </div>
      </SliderBox>
      <div style={{ height: '100px' }}></div>
      {/* <div className="circle-wrap">
        <div className="circle fs-14">联系方式</div>
        <div className="circle fs-14 flex-center">
          <i className="nc-icon nc-shouji fs-18 mr-2"></i>
          <span>15986907592</span>
        </div>
        <div className="circle fs-14 flex-center">
          <i className="nc-icon nc-wechat fs-20 mr-6"></i>
          <span>simsir_lin</span>
        </div>
      </div> */}
      {/* <SliderBox active={props.visible} time={1}>
        <div className="info">
          <p className="fs-14 lh-28">位置: 广东,深圳</p>
          <p className="fs-14 lh-28">邮箱: 15986907592@163.com</p>
          <p className="fs-14 lh-28">微信: simsir_lin</p>
          <img src="https://needcode.oss-cn-shenzhen.aliyuncs.com/common/file/giphy.webp" alt="" />
        </div>
      </SliderBox> */}
    </div>
  )
}
